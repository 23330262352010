import React, { useContext, useState } from "react";
import styled from "styled-components";
import WBCDataTable from "../../data-table/WBCDataTable";
import MLBPlayerSummaryContext from "../../../contexts/MLBPlayerSummaryContext";
import DocsFormatter from "../../interest-list/DocsFormatter";
import CheckMarkFormatter from "../../elements/CheckMarkFormatter";
import EligibleFormatter from "../../interest-list/EligibleFormatter";
import PlayerLinkFormatter from "../../table/PlayerLinkFormatter";
import RosterStatusFormatter from "./MLBPlayerRosterStatusFormatter";
import RequestStatusFormatter from "../../table/RequestStatusFormatter";
import { sortPlayers } from "../../roster/RosterSortHelper";
import TableSortFilterContext from "../../../contexts/TableSortFilterContext";

const MLBPlayerDetailsTableContainer = styled.div`
  classname: "d-flex ";
  padding: 1.5rem;
  padding-top: 0.5rem;
`;

const MLBPlayersDetailsTable = () => {
  // context variables
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const mlbPlayerSummaryContext = useContext(MLBPlayerSummaryContext);
  const { playerSummaries, statusSelected, fedFilter, orgFilter, sortFilters } = useContext(MLBPlayerSummaryContext);

  // filter functions
  const filterByStatus = playerSummariesList => {
    return playerSummariesList.filter(
      player => !statusSelected?.value || statusSelected?.label === player?.requestStatus
    );
  };

  const filterByFed = playerSummariesList => {
    return playerSummariesList.filter(player => !fedFilter || fedFilter == player?.federationCountryId);
  };
  const filterByOrg = playerSummariesList => {
    return playerSummariesList.filter(player => !orgFilter || orgFilter == player?.orgId);
  };

  const getPlayerSummaries = () => {
    if (playerSummaries && playerSummaries?.length) {
      return filterByFed(
        filterByOrg(
          filterByStatus(
            playerSummaries.filter(player => !["BOC", "MSB", "NONE", null, ""].includes(player?.org?.toUpperCase()))
          )
        )
      );
    }
    return [];
  };

  let columns = [
    { name: "Last", key: "lastName", width: 120, formatter: PlayerLinkFormatter, sortable: true },
    { name: "First", key: "firstName", width: 120, formatter: PlayerLinkFormatter, sortable: true },
    { name: "Pos", key: "position", width: 60, sortable: true },
    { name: "Docs", key: "hasDocuments", formatter: DocsFormatter, width: 60, sortable: true },
    { name: "Eligibility", key: "eligibility", formatter: EligibleFormatter, width: 100, sortable: true },
    { name: "Past WBC", key: "pastWbcFlag", formatter: CheckMarkFormatter, width: 100, sortable: true },
    { name: "Org", key: "org", width: 60, sortable: true },
    { name: "Club", key: "club", width: 140, sortable: true },
    { name: "IL", key: "injuredListFlag", formatter: CheckMarkFormatter, width: 60, sortable: true },
    { name: "40-man", key: "fortyManRosterFlag", formatter: CheckMarkFormatter, width: 80, sortable: true },
    {
      name: "MLB Roster Status",
      key: "ebisMlbRosterStatus",
      formatter: RosterStatusFormatter,
      sortable: true,
      width: 200
    },
    { name: "Federation", key: "federation", sortable: true, width: 120 },
    { name: "Highest Roster", key: "highestRoster", sortable: true, width: 150 },
    { name: "Request Status", key: "requestStatus", formatter: RequestStatusFormatter, sortable: true, width: 150 },
    { name: "Chronic Conditions", key: "insuranceStatus", formatter: CheckMarkFormatter, sortable: true, width: 150 }
  ];

  return (
    <MLBPlayerDetailsTableContainer>
      <WBCDataTable
        useBCR
        columns={columns}
        enableRowSelect={null}
        rowScrollTimeout={null}
        headerRowHeight={40}
        minHeight={400}
        rowHeight={45}
        data={getPlayerSummaries()}
        sortFunc={(col, dir) => {
          mlbPlayerSummaryContext.dispatch({
            col,
            dir,
            type: "sortPlayers",
            playersDisplayed: sortPlayers(col, dir, playerSummaries, false)
          });
          tableSortFilterContext.dispatch({
            type: "setSortFilters",
            sortFilters: { col, dir }
          });
        }}
        sortColumn={sortFilters.col}
        sortDirection={sortFilters.dir}
        minWidth={1800}
      ></WBCDataTable>
    </MLBPlayerDetailsTableContainer>
  );
};

export default MLBPlayersDetailsTable;
