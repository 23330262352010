import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import WBCCollapse from "../elements/WBCCollapse";
import PlayerTable from "./provisional/PlayerTable";
import RosterContext from "../../contexts/RosterContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import InnerCollapse from "../elements/InnerCollapse";
import TableSortFilterContext from "../../contexts/TableSortFilterContext";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";
import ExportApi from "../../httpClients/ExportApi";
import FileHelper from "../../httpClients/FileHelper";
import { FinalizeWrapper, FinalizeButton } from "../roster/RosterStyles";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import { PROVISIONAL_LIST } from "../../constants/RosterListConstants";
import AuthContext from "../../contexts/AuthContext";
import { formatDate } from "../../utils/DateUtils";

const Container = styled.div`
  margin-bottom: 21px;
`;

const ProvisionalCollapse = styled(WBCCollapse)`
  .wbc-collapse-subtitle {
    color: ${({ invalid, theme }) => (invalid ? theme.wbcRed : undefined)};
  }
`;

const ProvisionalRoster = () => {
  // context(s) && state
  const rosterContext = useContext(RosterContext);
  const { selectedWbcYear, selectedYear } = useContext(WBCYearsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const globalModalContext = useContext(GlobalModalContext);
  const tournamentType = useContext(TournamentTypeContext);
  const { isBOCadmin, isFedAdmin, isTeamCoordinator } = useContext(AuthContext).state;

  // variable(s)
  const { team } = selectedTeamContext.state;
  const { teamId: fedTeamId } = team;
  const { length: size } = rosterContext.state.provisional.players;
  const { provisionalIsOpen, staffIsOpen, playersIsOpen } = rosterContext.state;
  const { dispatch: rosterDispatch } = rosterContext;
  const settings = (selectedWbcYear && selectedWbcYear[`${tournamentType}TournamentSettings`]) || null;
  const provisionalMax = settings && settings.rosterNumbers.provisionalMax;
  const finalizedDate = team.finalizedStatus?.finalizedProvisionalDate;

  const rosterType = PROVISIONAL_LIST;

  const { sortFilters } = tableSortFilterContext.state;

  const finalizeRoster = () => {
    globalModalContext.dispatch({
      type: "openFinalizeRosterModal",
      headerText: `Finalize ${rosterType}`,
      content: { team, prefix: tournamentType, rosterType }
    });
  };

  // function(s)
  const exportFn = useCallback(() => {
    ExportApi.exportProvisionalRosterList(
      selectedYear,
      fedTeamId,
      tournamentType,
      sortFilters.col,
      sortFilters.dir,
      (url, headers) => ({
        url,
        headers
      })
    ).then(data => {
      const test = new Blob([data.url], { type: data.headers["content-type"] });
      const url = window.URL.createObjectURL(test);
      FileHelper.downloadFile(url, data.headers);
    });
  }, [sortFilters, selectedYear, fedTeamId, tournamentType]);

  const clickedPlayersCollapse = isOpen => {
    rosterDispatch({
      type: "setPlayersIsOpen",
      isOpen
    });
  };

  const clickedProvisionalCollapse = isOpen => {
    rosterDispatch({
      type: "setProvisionalIsOpen",
      isOpen
    });
  };

  return (
    settings && (
      <Container>
        <ProvisionalCollapse
          exportable
          startsOpen={provisionalIsOpen || staffIsOpen || playersIsOpen}
          openCloseCallback={clickedProvisionalCollapse}
          title={`Provisional (${size})`}
          subTitle={`${provisionalMax} player max`}
          invalid={size > provisionalMax}
          exportFn={exportFn}
        >
          <InnerCollapse title="Players" openCloseCallback={clickedPlayersCollapse} startsOpen={playersIsOpen}>
            <PlayerTable />
          </InnerCollapse>
          {(isBOCadmin || isTeamCoordinator || (isFedAdmin && !finalizedDate)) && (
            <FinalizeWrapper>
              <FinalizeButton
                onClick={event => {
                  event.stopPropagation();
                  finalizeRoster();
                }}
              >
                Finalize {rosterType}
              </FinalizeButton>
            </FinalizeWrapper>
          )}
          {!!finalizedDate && (
            <FinalizeWrapper>Finalized on {formatDate(finalizedDate, "MM/DD/YYYY HH:MM")}</FinalizeWrapper>
          )}
        </ProvisionalCollapse>
      </Container>
    )
  );
};

export default ProvisionalRoster;
