import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon } from "best-common-react";

const Header = styled.div.attrs(() => ({
  className: "d-flex inner-collapse-header"
}))`
  height: 30px;
  padding-left: 10px;
  background-color: ${props => props.theme["almost-white"]};
`;

const Title = styled.div.attrs(() => ({
  className: "ml-2"
}))`
  cursor: pointer;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: ${props => props.theme.wbcBlue};
`;

const InnerCollapse = ({ startsOpen, title, children, className, openCloseCallback }) => {
  const [isOpen, setIsOpen] = useState(startsOpen);

  const clickedCollapse = () => {
    const newState = !isOpen; // need this because updating state in react is not a guaranteed synchronous call

    setIsOpen(!isOpen);
    if (openCloseCallback) {
      openCloseCallback(newState);
    }
  };

  return (
    <div className={`mb-2 ${className || ""}`.trim()}>
      <Header onClick={clickedCollapse}>
        <Icon iconName={`fa-chevron-${isOpen ? "up" : "down"}`} />
        <Title>{title}</Title>
      </Header>
      {isOpen && children}
    </div>
  );
};

InnerCollapse.propTypes = {
  className: PropTypes.string,
  startsOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  openCloseCallback: PropTypes.func
};

InnerCollapse.defaultProps = {
  startsOpen: false
};

export default InnerCollapse;
